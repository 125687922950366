<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: `${page_route}${store_mode ? '' : '.view'}`,
              params: { provider_id: provider_id, id: id },
            }"
          />
          <span
            class="text-h6 ml-1"
            v-text="
              `${loading_provider ? '' : `${provider.name} | `}${
                $route.meta.title
              }`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-form ref="form_data" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-card
              class="mx-auto"
              :disabled="login.role_id === 11 ? true : false"
            >
              <v-card-title>
                <h2 class="text-caption" v-text="'GENERAL'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row>
                  <v-col cols="12" xs="12" md="6">
                    <v-autocomplete
                      label="Tipo*"
                      v-model="data.benefit_type_id"
                      :rules="rules.required"
                      :items="benefit_types"
                      item-value="id"
                      :item-text="(item) => item.benefit_type"
                      :loading="benefit_types_loading"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="6">
                    <v-text-field
                      label="Código*"
                      v-model="data.code"
                      :rules="rules.required"
                      maxlength="30"
                      type="text"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'DESCRIPCIONES'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col
                    cols="12"
                    v-for="(benefit_detail, i) in data.benefit_details"
                    :key="i"
                  >
                    <v-row v-if="benefit_detail.active">
                      <v-col cols="12" xs="12" md="3">
                        <v-text-field
                          :label="`Descripción* (${i + 1})`"
                          v-model="benefit_detail.description"
                          :rules="rules.text250Required"
                          type="text"
                          :disabled="login.role_id === 11 ? true : false"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="2">
                        <v-text-field
                          label="Precio*"
                          v-model="benefit_detail.amount"
                          :rules="rules.required"
                          type="number"
                          :disabled="login.role_id === 11 ? true : false"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="2">
                        <v-text-field
                          label="Frecuencia"
                          v-model="benefit_detail.frequency"
                          type="number"
                          :disabled="login.role_id === 11 ? true : false"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="2">
                        <v-autocomplete
                          label="Tipo*"
                          v-model="benefit_detail.benefit_type_id"
                          :rules="rules.required"
                          :items="benefit_types"
                          item-value="id"
                          :item-text="(item) => item.benefit_type"
                          :loading="benefit_types_loading"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="2">
                        <v-text-field
                          label="Factura única"
                          v-model="benefit_detail.bill"
                          maxlength="30"
                          type="text"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        md="1"
                        class="text-center"
                        align-self="center"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              text
                              icon
                              x-small
                              color="error"
                              @click="deleteBenefitDetail(i)"
                              :disabled="login.role_id === 11 ? true : false"
                            >
                              <v-icon medium v-text="'mdi-close'" />
                            </v-btn>
                          </template>
                          <span v-text="`Eliminar descripción (${i + 1})`" />
                        </v-tooltip>
                      </v-col>
                      <v-col cols="12" xs="12" md="12">
                        <v-textarea
                          label="Definición"
                          v-model="benefit_detail.definition"
                          rows="1"
                          :disabled="login.role_id === 11 ? true : false"
                        />

                        <v-divider />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      @click="addBenefitDetail"
                      color="warning"
                      :loading="loading"
                      x-small
                      block
                      dark
                      :disabled="login.role_id === 11 ? true : false"
                    >
                      <v-icon left v-text="'mdi-plus'" />
                      Agregar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card
              class="mx-auto"
              :disabled="login.role_id === 11 ? true : false"
            >
              <v-card-title>
                <h2 class="text-caption" v-text="'PRESTACIONES INCLUIDAS'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col
                    cols="12"
                    v-for="(benefit_included, i) in data.benefit_includes"
                    :key="i"
                  >
                    <v-row v-if="benefit_included.active">
                      <v-col cols="12" xs="12" md="11">
                        <v-autocomplete
                          :label="`Prestación* (${i + 1})`"
                          v-model="benefit_included.benefit_included_id"
                          :rules="rules.required"
                          :items="benefits"
                          item-value="id"
                          :item-text="
                            (item) =>
                              `${item.benefit_type.benefit_type} | ${item.code} | ${item.benefit_details[0].description}`
                          "
                          :loading="benefits_loading"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        md="1"
                        class="text-center"
                        align-self="center"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              text
                              icon
                              x-small
                              color="error"
                              @click="deleteBenefitInclude(i)"
                            >
                              <v-icon medium v-text="'mdi-close'" />
                            </v-btn>
                          </template>
                          <span v-text="`Eliminar prestación (${i + 1})`" />
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      @click="addBenefitInclude"
                      color="warning"
                      :loading="loading"
                      x-small
                      block
                      dark
                    >
                      <v-icon left v-text="'mdi-plus'" />
                      Agregar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card
              class="mx-auto"
              :disabled="login.role_id === 11 ? true : false"
            >
              <v-card-title>
                <h2 class="text-caption" v-text="'COBERTURA POR ASEGURADORA'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col
                    cols="12"
                    v-for="(
                      benefit_insurance_coverage, i
                    ) in data.benefit_insurance_coverages"
                    :key="i"
                  >
                    <v-row v-if="benefit_insurance_coverage.active">
                      <v-col cols="12" xs="12" md="3">
                        <v-autocomplete
                          :label="`Aseguradora* (${i + 1})`"
                          v-model="benefit_insurance_coverage.insurance_id"
                          :rules="rules.required"
                          :items="insurances"
                          item-value="id"
                          :item-text="(item) => item.name"
                          :loading="insurances_loading"
                        />
                      </v-col>

                      <v-col cols="12" xs="12" md="4">
                        <v-autocomplete
                          :label="`Cobertura* (${i + 1})`"
                          v-model="benefit_insurance_coverage.coverage_type_id"
                          :items="coverage_types"
                          item-value="id"
                          :item-text="(item) => item.coverage_type"
                          :loading="insurances_loading"
                        />
                      </v-col>

                      <v-col cols="12" xs="12" md="4">
                        <v-autocomplete
                          :label="'Contratante'"
                          v-model="benefit_insurance_coverage.contractor_id"
                          :items="contractors"
                          item-value="id"
                          :item-text="(i) => i.name"
                          :loading="contractors_loading"
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        xs="12"
                        md="1"
                        class="text-center"
                        align-self="center"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              text
                              icon
                              x-small
                              color="error"
                              @click="deleteCoverage(i)"
                            >
                              <v-icon medium v-text="'mdi-close'" />
                            </v-btn>
                          </template>
                          <span v-text="`Eliminar cobertura (${i + 1})`" />
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      @click="addCoverage"
                      color="warning"
                      :loading="loading"
                      x-small
                      block
                      dark
                    >
                      <v-icon left v-text="'mdi-plus'" />
                      Agregar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" class="text-right">
            <BtnCircle
              :tLabel="store_mode ? 'Crear' : 'Editar'"
              tPosition="top"
              :icon="store_mode ? 'mdi-plus' : 'mdi-pencil'"
              :color="store_mode ? 'success' : 'info'"
              size="small"
              :click="handleSubmit"
              :loading="loading"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
} from "../../control";
import { catalog } from "../../requests/catalogRequest";
import { storeUpdate, show, index } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    FaqDlg,
  },
  data() {
    return {
      page_route: "prestaciones",
      id: this.$route.params.id ? this.$route.params.id : 0,
      provider_id: this.$route.params.provider_id,
      store_mode: this.$route.params.id ? false : true,
      login: this.$store.getters.getLogin,
      loading: false,
      data: {
        id: null,
        code: "",
        benefit_type_id: null,
        provider_id: this.$route.params.provider_id,
        created_by_id: this.$store.getters.getLogin.id,
        updated_by_id: this.$store.getters.getLogin.id,
        benefit_details: [],
        benefit_includes: [],
        benefit_insurance_coverages: [],
      },
      provider: {},
      providers: [],
      loading_provider: true,
      rules: rules(),
      benefit_types: [],
      benefit_types_loading: true,
      benefits: [],
      benefits_loading: true,
      insurances: [],
      insurances_loading: true,
      coverage_types: [],
      coverage_types_loading: true,
      contractors: [],
      contractors_loading: true,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    handleSubmit() {
      if (this.$refs.form_data.validate()) {
        if (this.data.benefit_details.length > 0) {
          this.$swal
            .fire(
              msgConfirm(
                `¿Confirma la ${
                  this.store_mode ? "creación" : "edición"
                } del registro?`
              )
            )
            .then((response) => {
              if (response.isConfirmed) {
                this.loading = true;
                storeUpdate(
                  "benefits",
                  this.login.token,
                  this.id,
                  this.data,
                  this.login.id
                ).then((response) => {
                  this.$swal.fire(
                    msgAlert(
                      response.success ? "success" : "error",
                      response.message
                    )
                  );

                  response.success
                    ? this.$router.push({
                        name: `${this.page_route}${
                          this.store_mode ? "" : ".view"
                        }`,
                        params: { id: this.id },
                      })
                    : console.log(response.message);
                });

                this.loading = false;
              }
            });
        } else {
          this.$swal.fire(
            msgAlert(
              "error",
              "Debe de existir al menos una descripción agregada"
            )
          );
        }
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    addBenefitDetail() {
      this.data.benefit_details.push({
        id: null,
        description: "",
        amount: "",
        definition: "",
        frequency: "0",
        bill: "",
        active: 1,
      });
    },
    deleteBenefitDetail(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar la descripción (${i + 1})?`))
        .then((response) => {
          if (response.isConfirmed) {
            if (this.data.benefit_details[i].id === null)
              this.data.benefit_details.splice(i, 1);
            else this.data.benefit_details[i].active = false;
          }
        });
    },
    addBenefitInclude() {
      this.data.benefit_includes.push({
        id: null,
        benefit_included_id: null,
        active: 1,
      });
    },
    deleteBenefitInclude(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar la prestación (${i + 1})?`))
        .then((response) => {
          if (response.isConfirmed) {
            if (this.data.benefit_includes[i].id === null)
              this.data.benefit_includes.splice(i, 1);
            else this.data.benefit_includes[i].active = false;
          }
        });
    },
    addCoverage() {
      this.data.benefit_insurance_coverages.push({
        id: null,
        insurance_id: null,
        contractor_id: null,
        coverage_type_id: null,
        active: 1,
      });
    },
    deleteCoverage(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar la cobertura (${i + 1})?`))
        .then((response) => {
          if (response.isConfirmed) {
            if (this.data.benefit_insurance_coverages[i].id === null)
              this.data.benefit_insurance_coverages.splice(i, 1);
            else this.data.benefit_insurance_coverages[i].active = false;
          }
        });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    show("providers", this.login.token, this.provider_id).then((response) => {
      this.provider = response;
    });

    catalog("benefit_types", this.login.token).then((response) => {
      this.benefit_types = response;
      this.benefit_types_loading = false;
    });

    index("insurances", this.login.token, [
      {
        name: "active",
        value: 1,
      },
      {
        name: "id",
        value: this.login.id,
      },
    ]).then((response) => {
      this.insurances = response.data;
      this.insurances_loading = false;
    });

    index("contractors", this.login.token, []).then((response) => {
      this.contractors = response.data;
      this.contractors.push({
        id: null,
        name: "TODOS",
        active: 1,
      });
      this.contractors_loading = false;
    });

    index("coverage/types", this.login.token, []).then((response) => {
      this.coverage_types = response.data;
      this.coverage_types_loading = false;
    });

    index("benefits/provider/" + this.provider_id, this.login.token, []).then(
      (response) => {
        this.benefits = response.data;
        this.benefits_loading = false;
      }
    );

    if (!this.store_mode) {
      this.loading = true;

      show("benefits", this.login.token, this.id).then((response) => {
        this.data = response;
        this.loading = false;
      });
    }
  },
};
</script>